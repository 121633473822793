@tailwind utilities;
@tailwind components;

.react-datepicker-wrapper input {
  @apply border-2 w-full border-color-border-mid outline-none focus:border-color-brand-primary-1-base py-mob-global-spacing-spacing-3 lg:py-desk-global-spacing-spacing-3 px-mob-global-spacing-spacing-4 lg:px-desk-global-spacing-spacing-4 placeholder:text-color-text-black rounded-mob-global-radius-small lg:rounded-desk-global-radius-small;
}
@layer components {
  .react-datepicker {
    @apply !border-color-border-light;
    button {
      @apply bg-color-brand-primary-1-base;
    }
    /* .react-datepicker__day--today {
      @apply !bg-color-brand-primary-1-disabled;
    } */
    .react-datepicker__day--selected,
    .react-datepicker__day--selected.react-datepicker__day--today {
      @apply bg-color-brand-primary-1-base rounded-[50%] !text-white;
    }
  }
}

/**parent wrapper */
.react-datepicker-wrapper {
  @apply w-full;
}
/**month and day wrapper */
.react-datepicker {
  padding: 4px;
  min-width: 292px;
  .react-datepicker__month-container {
    width: 100%;
  }
  .react-datepicker__month {
    .react-datepicker__week {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    .react-datepicker__day--keyboard-selected {
      background-color: white !important;
    }
    .react-datepicker__day--selected {
      /* background-color: theme('colors.color-brand-primary-1-base'); */
      color: white;
      border-radius: 50%;
    }
    .react-datepicker__day--today {
      border-radius: 50%;
      color: black;
      font-weight: normal;
    }
  }
}
/**Button */
.react-datepicker button {
  top: 10px;
  /* background-color: theme('colors.color-brand-primary-1-base'); */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  span:nth-child(1) {
    top: 2px !important;
    left: -1px;
  }
  .react-datepicker__navigation-icon--next {
    top: 2px !important;
    left: 1px !important;
  }

  span::before {
    border-color: white !important;
  }
}
/**input tag*/
/* .react-datepicker-wrapper input::placeholder {
  color: #000;
}
.react-datepicker-wrapper input {
  outline: none;
  width: 100%;
  border: 2px solid theme('colors.color-border-mid');
  background-color: theme('colors.color-background-white');
  font-size: theme('fontSize.body-large-regular');
  font-weight: theme('fontSize.body-large-regular');
  padding-block: theme('spacing.mob-global-spacing-spacing-3');
  padding-inline: theme('spacing.mob-global-spacing-spacing-4');
  border-radius: theme('borderRadius.mob-global-radius-small');
}
.react-datepicker-wrapper input:focus {
  border: 2px solid theme('colors.color-brand-primary-1-base');
} */

.react-datepicker__tab-loop {
  position: relative;
}
.react-datepicker__tab-loop svg {
  display: none;
}

/**day name*/
.react-datepicker__header {
  /* @apply font-body-large-regular; */
  background-color: theme('colors.color-background-white') !important;
  border: 0px !important;
  .react-datepicker__day-names {
    margin-top: 10px;
    padding-block: 10px;
    display: flex;
    justify-content: space-between;
  }
}
.react-datepicker-popper {
  width: 100%;
  transform: translate(0, 0) !important;
}
/* } */
/* 'outline-none',
        'border-2',
        'text-color-text-black',
        'text-body-large-regular',
        'font-body-large-regular',
        'leading-body-large-regular',
        'placeholder:text-color-text-black',
        'focus:border-color-brand-primary-1-base',
        'focus:placeholder:opacity-0',
        'placeholder:transition-opacity',
        'placeholder:duration-500',
        'focus:border-color-brand-primary-1-base',
        'disabled:bg-color-scale-3-light-mid',
        'w-full',
        'appearance-none',
        'bg-color-scale-1-white', */
