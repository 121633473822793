/* stylelint-enable rule */
@tailwind base;
@tailwind components;
@tailwind utilities;

a[target='_blank']:after {
  content: '\1F5D7';
}

/*
  Hides Sitecore editor markup,
  if you run the app in connected mode while the Sitecore cookies
  are set to edit mode.
*/
.scChromeData,
.scpm {
  display: none !important;
}

/*
  Styles for default JSS error components
*/
.sc-jss-editing-error,
.sc-jss-placeholder-error {
  padding: 1em;
  background-color: lightyellow;
}

/* 
  Style for default content block
*/
.contentTitle {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:after {
  content: '' !important;
}

/** if we don't need scrollbar  */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Adding fontFamily here as only these would be followed up: */
.psp .font-primary,
.psp .font-secondary,
body .psp {
  font-family: 'effra', sans-serif;
}

.wnw .font-primary,
body .wnw {
  font-family: 'filson-soft', sans-serif;
}

.wnw .font-secondary,
.wnw h1 {
  font-family: 'filson-soft';
  font-weight: 900;
}

.video-aspect-ratio {
  width: 100vw !important;
  height: 100vh !important;
}

@media (min-aspect-ratio: 16 / 9) {
  .video-aspect-ratio {
    height: 100vw !important;
  }
}

@media (max-aspect-ratio: 16 / 9) {
  .video-aspect-ratio {
    width: 177.78vh !important;
  }
}

.checkout.container-default {
  @apply container flex w-full p-[16px] md:p-[40px];
}

/* .checkout.container-default:has(> .component-content * > * > .PLP) {
  @apply max-w-[100%];
} */
.checkout.container-default .component-content {
  @apply w-full;
}

.checkout.container-default > .component-content > .row {
  @apply grid grid-flow-row lg:grid-flow-col lg:auto-rows-auto auto-cols-auto gap-x-[40px];
}

.checkout.container-default > .component-content > .row > .left-container {
  @apply lg:col-span-1;
}
.checkout.container-default > .component-content > .row > .right-container {
  @apply lg:row-span-2 lg:col-span-1 lg:order-last lg:min-w-[360px] lg:max-w-[360px];
}

.checkout.container-default .column-splitter {
  @apply flex flex-col-reverse md:flex-row;
}

/* PSP-2296: If want to keep checkout classes on PLP: */
.listing-container .checkout.container-default {
  @apply !px-[16px] !px-0;
}
.listing-container {
  @apply !px-[16px] lg:!px-0;
}
/* .w-75 {
  @apply md:mr-[10%] md:w-[70%] w-full;
}

.w-25 {
  @apply md:w-[30%] w-full;
} */

.w-75 {
  @apply md:mr-[5%] md:w-[70%] lg:mr-[2%] w-full;
}

.w-25 {
  @apply md:w-[33%] w-full;
}

.product-weight-clip {
  clip-path: polygon(100% 0, 84% 49%, 100% 100%, 0 98%, 0 50%, 0 0);
}

/* for preventing scroll on modal open */
.modal-open {
  overflow: hidden;
  touch-action: none;
  position: fixed;
  width: 100%;
  height: 100%;
}

.minicart-lineitems-wrapper::-webkit-scrollbar-thumb {
  @apply bg-color-background-brand-1;
}
.minicart-lineitems-wrapper::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
.minicart-lineitems-wrapper::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
.backdrop-shadow:after {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0.5;
  z-index: 888;
  content: '';
  @apply bg-color-background-black;
}
.backdrop-shadow {
  z-index: 950;
  position: relative;
}
/* Need to add here as tailwind is not directly supporting this */
.pdp-thumbnail-slider .splide__track--ttb {
  height: 100% !important;
  max-height: 468px !important;
}

/* CrowdTwist widgets iframes modal */
#ct-modal button {
  right: 20px !important;
  bottom: -30px !important;
  color: #00af41 !important;
  z-index: 10100;
}

/* Bazar Voice */
.ratingSummary[data-bv-show='rating_summary'] .bv_main_container .bv_stars_component_container {
  padding-right: 4px !important;
}

.ratingSummary[data-bv-show='rating_summary'] .bv_main_container .bv_main_container_row_flex {
  padding-right: 12px !important;
}

.ratingSummary[data-bv-show='rating_summary'] .bv_main_container .bv_numReviews_text,
.ratingSummary[data-bv-show='rating_summary'] .bv_main_container .bv_numReviews_text:hover {
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #231f1f !important;
}

.ratingSummary[data-bv-show='rating_summary'] .bv_main_container .bv_button_buttonMinimalist {
  text-decoration: underline !important;
}

.ratingSummary[data-bv-show='rating_summary'] .bv_main_container .bv_button_buttonMinimalist,
.ratingSummary[data-bv-show='rating_summary'] .bv_main_container .bv_button_buttonMinimalist:hover {
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

.ratingSummary[data-bv-show='rating_summary'] .bv_main_container .bv_button_buttonMinimalist:hover {
  text-decoration: none !important;
}

/* End Bazar Voice */

/* Icons transformation */
[data-state='open'] .sortIcon {
  transform: rotate(180deg);
}

[data-bv-show='rating_summary'] .bv_main_container .bv_avgRating_component_container.notranslate {
  display: none !important;
}
.ratingSummary[data-bv-show='rating_summary']
  .bv_main_container
  .bv_main_container_row_flex:last-child {
  padding-right: 0 !important;
}
.ratingSummary[data-bv-show='rating_summary']
  .bv_main_container
  .bv_main_container_row_flex
  .bv_button_component_container
  button:hover {
  text-decoration: underline !important;
}

/* Adyen Checkout Radio Button for saved Cards */
.adyen-checkout__payment-method__header__title {
  position: relative !important;
  padding-left: 30px !important;
}
.adyen-checkout__payment-method__radio {
  position: absolute !important;
  left: 0% !important;
}

#checkoutpayment
  .adyen-checkout__dropin
  .adyen-checkout__payment-method--selected
  .adyen-checkout__payment-method__header
  .adyen-checkout__button.adyen-checkout__button--link {
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: underline;
  font-size: 18px;
  @apply text-color-brand-primary-1-base;
}

#checkoutpayment .adyen-checkout__payment-method {
  border: 1px solid #e6e9eb !important;
  cursor: pointer;
  margin-top: -1px;
  position: relative;
  transition: opacity 0.3s ease-out;
  width: 100%;
  display: flex !important;
  padding: 0 16px;
  @apply !bg-color-background-white;
}

#checkoutpayment .adyen-checkout__dropin .adyen-checkout__payment-method--selected {
  @apply !border;
  @apply !border-color-background-light;
  @apply !bg-color-background-light;
  display: block !important;
  padding: unset;
}
#checkoutpayment
  .adyen-checkout__dropin
  .adyen-checkout__card-input
  .adyen-checkout-form-instruction {
  display: none;
}

.visualFilters .splide__track.splide__track--loop.splide__track--ltr.splide__track--draggable,
.visualFilters .splide__track.splide__track--slide.splide__track--ltr.splide__track--draggable {
  overflow: unset;
  overflow-x: clip;
}

@media only screen and (max-width: 767px) {
  body .alertPromo:not(.hidden) + #header .mini-cart-utility-open:not(.fixed) {
    top: -100%;
  }
  body .alertPromo + #header .mini-cart-utility-open:not(.fixed) {
    top: 10px;
  }
  .minicart-lineitems-wrapper {
    height: 150px;
  }
}

@media (min-width: 1280px) and (max-width: 1311px) {
  .spefic-range-wrapper-min-h {
    min-height: 296px !important;
  }
  .specific-range-cta-h {
    height: 68px !important;
  }
}

.sitecoreProductLink a {
  height: 100%;
  display: inline-block;
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  text-decoration: none;
  cursor: pointer;
  word-wrap: break-word;
}

/** changing icon on hover of location icon in autosuggestion dropdown*/
.pac-icon {
  width: 20px;
}
.pac-item:hover .pac-icon {
  background-image: url('/images/paw.png');
  background-size: cover;
  background-position: center;
  width: 20px;
}
/* Store address modal in geo locator */
.pac-container {
  z-index: 1200 !important;
}

@media (min-width: 1024px) {
  .check-for-groomers-wrapper .react-datepicker-wrapper {
    width: 203px;
    display: block;
  }
}

@media (max-width: 1290px) and (min-width: 767px) {
  #header ul {
    grid-column: span 6 / span 6;
    grid-column-start: 1;
  }
  #header ul + div {
    grid-column: 1 / span 10; /* Start at column 2 and span 10 columns */
    margin-right: auto; /* Ensure no extra space on the right */
    margin-left: auto; /* Ensure no extra space on the left */
    padding-left: 16px;
    padding-right: 16px;
    width: auto !important;
  }
}

/** hiding some html on page print*/
@media print {
  .globalSearch {
    display: none !important;
  }
  .mobileStoreLocator {
    border-top: solid 2px rgb(var(--colors-color-brand-primary-1-base)) !important;
    border-bottom: solid 2px rgb(var(--colors-color-border-mid)) !important;
  }
  .printBtn {
    border: solid 2px rgb(var(--colors-color-brand-primary-1-base)) !important;
    width: 100% !important;
    max-width: 100% !important;
  }
  .cartItem {
    display: none !important;
  }
  .printIcon * {
    display: flex !important;
  }
}
@import './bazaarvoice.css'; /* Importing the styles from styles.module.css */

/** Global Header**/
@media (max-width: 1400px) and (min-width: 767px) {
  #leftUtilityNav ul li.top-level-parent,
  #rightUtilityNav ul li.top-level-parent {
    padding-inline: 5px !important;
  }
}
/* @media (max-width: 1308px) and (min-width: 767px) {
  .productList {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }
} */

/** For PLP **/
@media (max-width: 1308px) and (min-width: 767px) {
  .checkout.container-default:has(> .component-content * > * > .PLP) {
    max-width: 100% !important;
    padding-inline: 22px !important;
  }
}

/** For PDP Address selection boxes **/
@media (max-width: 1312px) and (min-width: 767px) {
  .PDP_address_selection {
    gap: 20px !important;
    .PDP_address_selection_pickup,
    .PDP_address_selection_delivery {
      padding: 15px !important;
    }
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .checkout.container-default {
    padding: 20px;
  }
  .checkout .price-wrapper {
    gap: 20px;
  }
  .checkout .product-wrapper {
    padding-right: 20px !important;
  }
  .checkout .product-heading,
  .checkout .price-wrapper p {
    @apply text-body-small-bold;
  }
}

.pDPComponent [data-bv-show='rating_summary'] .bv_main_container .bv_stars_component_container,
.pDPComponent [data-bv-show='rating_summary'] .bv_main_container div.bv_main_container_row_flex,
.myAutoship [data-bv-show='rating_summary'] .bv_main_container .bv_stars_component_container {
  padding-right: 0 !important;
}

.pDPComponent [data-bv-show='rating_summary'] .bv_main_container .bv_button_buttonMinimalist,
.pDPComponent [data-bv-show='rating_summary'] .bv_main_container .bv_button_buttonMinimalist:hover {
  text-decoration: underline !important;
  text-align: center !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 24px !important;
}

.pDPComponent
  [data-bv-show='rating_summary']
  .bv_main_container
  .bv_numReviews_component_container
  .bv_numReviews_text,
.myAutoship
  [data-bv-show='rating_summary']
  .bv_main_container
  .bv_numReviews_component_container
  .bv_numReviews_text {
  color: black !important;
}

.myAutoship [data-bv-show='rating_summary'] div .bv_button_buttonMinimalist.bv_war_button {
  margin-top: 12px !important;
  color: #000 !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  text-decoration-line: underline !important;
}

/** New Header R2 **/

@media (min-width: 1024px) and (max-width: 1280px) {
  #desknav .col-three-parent {
    min-width: 220px !important;
  }
  #desknav .col-two-items {
    min-width: 170px !important;
    padding-inline: 7px;
  }
  #desknav .desk-cols {
    min-width: 220px !important;
  }
  #desknav .col-two-parent {
    width: 100%;
    max-height: 494px;
  }
  #desknav .col-one-parent {
    min-width: 180px !important;
  }
  #desknav .col-one-parent .colLayoutOneParentItem {
    font-size: 14px;
  }
  #desknav .col-two-parent .colLinkItems {
    font-size: 14px;
  }
  #desknav .shopNavigation .colLinkItems {
    font-size: 14px;
  }
  #desknav .deskNavOpen .colLinkItems {
    font-size: 14px;
  }
  #desknav .col-two-parent .colLinkWrapper {
    font-size: 16px;
  }
  #desknav .deskSubNavHeading {
    font-size: 16px;
  }
  #desknav .shopNavigation {
    max-height: 494px;
  }
  #desknav .shopColumnWrapper {
    padding-block-end: 0;
  }
  #desknav .desk-rows .col-three-parent {
    min-width: 208px !important;
  }
  #desknav .brands-main-wrapper .brand-icons-wrapper {
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
  }
  #desknav .deskNavOpen {
    transform: translate(-55%);
  }
  #desknav .col-two-items .headerParentTitle {
    font-size: 12px !important;
  }
  #desknav .popular-categories-wrapper {
    overflow-x: auto;
    /* max-width: 565px !important; */
  }
  #desknav .popularCategoriesTitleItemImgWrapper {
    width: 60px;
    height: 60px;
  }
  #desknav .globalSearch .searchForm .searchInput {
    max-width: 164px;
  }
  #desknav .col-one-parent {
    min-width: 140px !important;
  }
  #desknav .col-one-parent > *,
  #desknav .shopColumnWrapper {
    padding-inline: 16px;
  }
}

@media (min-width: 1280px) and (max-width: 1350px) {
  #desknav .col-three-parent {
    min-width: 220px !important;
  }
  #desknav .brands-main-wrapper .brand-icons-wrapper {
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
  }
}

@media (min-width: 1024px) and (max-width: 1107px) {
  #desknav .shopNavigation {
    margin-top: 34px;
  }
}

@media (min-width: 1280px) and (max-width: 1439px) {
  #desknav .deskNavOpen {
    transform: translate(-45%);
  }
}

/**My Purchase Details BazarVoice */

.purchaseDetails [data-bv-show='rating_summary'] .bv_main_container .bv_button_buttonMinimalist,
.purchaseDetails
  [data-bv-show='rating_summary']
  .bv_main_container
  .bv_button_buttonMinimalist:hover {
  text-decoration: underline !important;
  text-align: center !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  color: #000 !important;
}
.purchaseDetails [data-bv-show='rating_summary'] .bv_main_container {
  display: flex !important;
  flex-direction: column;
  align-items: end;
  gap: 4px;
}
.purchaseDetails [data-bv-show='rating_summary'] .bv_main_container .bv_main_container_row_flex {
  padding-inline-end: 0px !important;
}
.purchaseDetails [data-bv-show='rating_summary'] .bv_main_container .bv_numReviews_text {
  color: #000 !important;
}
.purchaseDetails [data-bv-show='rating_summary'] .bv_main_container .bv_stars_component_container {
  padding-inline-end: 4px !important;
}
/**My Purchase Details Print receipt */

@media print {
  *:has(.purchaseDetails) {
    #desknav,
    #leftUtilityNav,
    #rightUtilityNav,
    .bottom-utility-nav,
    .printReceipt,
    .orderSummary,
    .topDetailsWrapper,
    .orderSummaryWrapper,
    .addToCartWrapper,
    .topWrapper,
    .totalItems,
    .priceAndReviewWrapper,
    .favoriteIcon,
    .alertPromo,
    .breadcrumbContainer {
      display: none;
    }
    .pspContainer:has(~ .purchaseDetails) {
      display: none;
    }
    .deskUtilityWrapper {
      display: block !important;
    }
    .purchaseDetails + * {
      display: none;
    }
    .purchaseDetails {
      max-width: none !important;
      padding: 0;
    }
    .detailsAndLineItemsWrapper {
      width: 100%;
    }
    .utilityImage {
      top: 0px;
      position: relative;
      justify-content: center;
      display: flex;
    }
    .imageAndPriceWrapper {
      flex-direction: row;
    }
    .sectionWrapper {
      padding: 0;
    }
    .printPriceWrapper {
      margin-top: var(--spacing-desk-global-spacing-spacing-2);
      display: flex;
    }
    .printQtyPriceWrapper {
      margin-top: var(--spacing-desk-global-spacing-spacing-1);
    }
    .productTitle {
      font-weight: 400;
    }
    .printOrderTopSection {
      display: block;
    }
    .printOrderLabel,
    .printOrderDetails,
    .imageTagWrapper,
    .itemUnavailable,
    .itemPromo,
    .colorLight,
    .saleLabel,
    .retailTagWrapper {
      print-color-adjust: exact;
    }
    .productNameAndPrice {
      width: 100%;
      border: 0px;
      border-bottom: 1px;
    }
    .printOrderSummary {
      display: block;
      break-inside: avoid;
    }
    .LineItemCardWrapper {
      break-inside: avoid;
      border-bottom: 1px !important;
      border-style: solid !important;
      border-color: rgb(204, 204, 204) !important;
    }

    #footer {
      display: none;
    }
  }
}

@media (min-width: 1024px) and (max-width: 1311px) {
  .store-info-image {
    max-height: 315px !important;
    min-height: 315px !important;
  }
}

@media (min-width: 1024px) {
  .pspContainer:has(~ .purchaseDetails) {
    display: none;
  }
}

#maintenanceMode {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  h2 {
    font-size: 40px;
  }
  #article {
    display: block;
    text-align: lft;
    width: 650px;
    height: fit-content;
  }
  a {
    color: 519233;
    text-decoration: none;
  }
  a:hover {
    color: #333;
    text-decoration: none;
  }
}

/** Service Panel */
.servicePanel {
  .splide__track {
    height: 100%;
  }
}

/**Frequently Brought Together*/
@media (min-width: 1024px) and (max-width: 1311px) {
  .frequentlyBroughtProduct .productAndCtaWrapper {
    flex-direction: column;
  }
}

@supports (-webkit-backdrop-filter: blur(10px)) {
  .frequentlyBroughtProduct .fbImage {
    /* Specific Safari blend mode handling */
    mix-blend-mode: darken; /* or other desired mode */
  }
}
.frequentlyBroughtProduct .fbImage {
  transform: translate3d(0, 0, 0);
}

/** Product Recommendation*/
@media (min-width: 1024px) {
  .productRecommendation {
    .splide__track {
      margin-inline: var(--spacing-desk-global-spacing-spacing-7) !important;
      overflow-x: hidden;
    }
    .splide .splide__list {
      overflow-x: unset;
    }
  }
}

@media (max-width: 1024px) {
  .productRecommendation {
    .splide__track::-webkit-scrollbar {
      display: none;
    }
    .splide__track {
      overflow-x: scroll !important;
    }
  }
  .cartItemListing + .productRecommendation {
    .splide {
      width: calc(100vw - 56px);
    }
  }
}

@media (min-width: 832px) {
  .TwoColumnPages {
    max-width: 881px;
    padding-inline: 0px !important;
    margin-block-start: 24px;
  }
  .TwoColumnPages .productRecommendation .carouselWrapper .sitecoreProductRoot {
    max-width: 235px !important;
  }
}
@media (max-width: 832px) {
  .TwoColumnPages {
    max-width: 100vw;
  }
  .TwoColumnPages {
    margin-block-start: 24px;
  }
}

@supports (-webkit-backdrop-filter: blur(10px)) {
  .productRecommendation .prImage {
    /* Specific Safari blend mode handling */
    mix-blend-mode: darken; /* or other desired mode */
  }
}
.productRecommendation .prImage {
  transform: translate3d(0, 0, 0);
}
