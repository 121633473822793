@tailwind utilities;

@layer utilities {
  .rte h1 {
    @apply text-heading-mob-xLarge-bold font-heading-mob-xLarge-bold leading-heading-mob-xLarge-bold lg:text-heading-desk-xLarge-bold lg:font-heading-desk-xLarge-bold lg:leading-heading-desk-xLarge-bold;
  }
  .rte h2 {
    @apply text-heading-mob-large-bold font-heading-mob-large-bold leading-heading-mob-large-bold lg:text-heading-desk-large-bold lg:font-heading-desk-large-bold lg:leading-heading-desk-large-bold;
  }
  .rte h3 {
    @apply text-heading-mob-medium-bold font-heading-mob-medium-bold leading-heading-mob-medium-bold lg:text-heading-desk-medium-bold lg:font-heading-desk-medium-bold lg:leading-heading-desk-medium-bold;
  }
  .rte h4 {
    @apply text-heading-mob-medium-bold font-heading-mob-medium-bold leading-heading-mob-medium-bold lg:text-heading-desk-medium-bold lg:font-heading-desk-small-bold lg:leading-heading-desk-small-bold;
  }
  .rte a {
    @apply text-color-text-brand-1 no-underline hover:underline hover:text-color-brand-tertiary-3-base focus:text-color-brand-tertiary-3-base;
  }
  .rte .error {
    @apply text-system-red;
  }
}
.rte-align-right {
  @apply text-right;
}
.rte ol,
ul {
  display: inline-block;
}
.rte-align-left {
  @apply text-left;
}
.rte-align-center {
  @apply text-center;
}
.rte-indent-1 {
  @apply text-left;
}
.rte-align-justify {
  @apply text-justify;
}
/* Adding to incorporate basic ul stylings in RTE */
.rte ul {
  margin-left: 17px !important;
}
.rte ol {
  margin-left: 15px !important;
}
.rte ul {
  list-style-type: disc;
}
.rte ol {
  list-style-type: decimal;
}
